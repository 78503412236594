import { Card, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import RightArrow from "../assets/Icons/go.svg";
import Clm2Img from "../assets/Icons/mail.svg";
import Clm1Img from "../assets/Icons/phone.svg";
import BackArrowIcon from "../assets/Images/accordian.svg";
import scrollImage from "../assets/Images/mobileView/TeamAugNoShadow.png";
import BannerBgImage from "../assets/Images/TeamAugmentation/TeamAugmentation-banner.png";
import ARUP from "../assets/Images/TeamAugmentation/BusinessBenefited/ARUP.png";
import Hedgehog from "../assets/Images/TeamAugmentation/BusinessBenefited/Hedgehog.png";
import MROPRO from "../assets/Images/TeamAugmentation/BusinessBenefited/MROPRO.png";
import CERN from "../assets/Images/TeamAugmentation/BusinessBenefited/CERN.png";

import NetworkPlus from "../assets/Images/TeamAugmentation/BusinessBenefited/NetworkPlus.png";
import MiniCardBgImage8 from "../assets/Images/Homepage/digital_transformation.png";
import MiniCardBgImage7 from "../assets/Images/Homepage/code_ux_audit.png";
import MiniCardBgImage3 from "../assets/Images/Homepage/full_product_development.png";
import MiniCardBgImage9 from "../assets/Images/Homepage/machine_learning.png";
import MiniCardBgImage1 from "../assets/Images/Homepage/mobile_application_development.png";
import MiniCardBgImage4 from "../assets/Images/Homepage/MVP.png";
import MiniCardBgImage5 from "../assets/Images/Homepage/Product_designing_and_Prototyping.png";
import MiniCardBgImage6 from "../assets/Images/Homepage/product_scaling.png";
import MiniCardBgImage2 from "../assets/Images/Homepage/web_application_development.png";
import hoverCardImg5 from "../assets/Images/HowWeDoIt/Centralised_repository.svg";
import hoverCardImg2 from "../assets/Images/HowWeDoIt/Completely_Automated_SSDLC.svg";
import hoverCardImg3 from "../assets/Images/HowWeDoIt/Cost_cutting_on_Maintenance_Updates.svg";
import hoverCardImg4 from "../assets/Images/HowWeDoIt/Reduced_Implementation_Time.svg";
import hoverCardImg1 from "../assets/Images/HowWeDoIt/Synchronised_Development_Operations.svg";
import MiniImageBannerBgImg1 from "../assets/Images/HowWeDoIt/who_we_work_with.png";
import MiniImageBannerBgImg from "../assets/Images/contact_us_bg.png";
import ogimage from "../assets/Images/ogImages/OG_TeamAug.jpg";
import clutchLogo from "../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import Button from "../components/Button";
import Layout from "../components/layouts/layout";
import MiniCardWithBgImg from "../components/MiniCardWithBgImg";
import MobileSmoothSlider from "../components/MobileSmoothSlider";
//PlainCard
import PlainCard from "../components/SixPlainCard";
import DataService from "../services/api/data_services";
import { BASE_SITE_URL } from "../shared/constants";
//BlurCardList
import BlurCardList from "../templates/BlurCardList";

//Image Banner
import ImageBanner from "../templates/ImageBanner";
//Info View
import Infoview from "../templates/InfoView";
import Megaview from "../templates/MegaView";
//Mini Image Banner1
import MiniImageBanner1 from "../templates/MiniImageBanner1";
//Miniview
import Miniview from "../templates/MiniView";

//Medium View
import MediumviewWithHover from "../templates/MediumViewWithHover";
import MediumviewImage1 from "../assets/Images/Hire Remote team/deliver great employee.jpg";
import MediumviewImage2 from "../assets/Images/Hire Remote team/hiresomeone.jpg";
import MediumviewImage3 from "../assets/Images/Hire Remote team/stay complaint.jpg";

// Two Column Contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";
import BusinessBenifited from "../assets/Images/mobileView/Business_benifitted.png";

import mapImage1 from "../assets/Images/mobileView/Our_Locations/OurLocation.png";
import mChennai from "../assets/Images/mobileView/Our_Locations/Chennai.png";
import mBangalore from "../assets/Images/mobileView/Our_Locations/bangalore.png";
import mCoimbatore from "../assets/Images/mobileView/Our_Locations/Coimbatore.png";
import mHyderabad from "../assets/Images/mobileView/Our_Locations/Hyderabad.png";
import Manchester from "../assets/Images/mobileView/Our_Locations/Manchester.png";
import mMumbai from "../assets/Images/mobileView/Our_Locations/Mumbai.png";

//Icon Card List
import IconCardList from "../templates/IconCardList";
import IconCardListIcon1 from "../assets/Images/Hire Remote team/Group 6127.svg";
import IconCardListIcon7 from "../assets/Images/Hire Remote team/ReductionOfEmployee.svg";
import IconCardListIcon3 from "../assets/Images/Hire Remote team/Registration.svg";
import IconCardListIcon4 from "../assets/Images/Hire Remote team/Teamwork.svg";
import IconCardListIcon5 from "../assets/Images/Hire Remote team/Group 6142.svg";
import IconCardListIcon6 from "../assets/Images/Hire Remote team/Valuations.svg";
import IconCardListIcon2 from "../assets/Images/Hire Remote team/Ability to Connect.svg";

//Six Hover Cards
import SixPlainCards from "../templates/SixPlainCards";
import HoverCardWithBtnIcon2 from "../assets/Images/DigirytePods/WhyDigiryte/25+Yrs.svg";
import HoverCardWithBtnIcon1 from "../assets/Images/DigirytePods/WhyDigiryte/ReadilyAvailable.svg";
import HoverCardWithBtnIcon5 from "../assets/Images/DigirytePods/WhyDigiryte/SkilledResource.svg";
import HoverCardWithBtnIcon4 from "../assets/Images/DigirytePods/WhyDigiryte/SOP.svg";
import HoverCardWithBtnIcon3 from "../assets/Images/DigirytePods/WhyDigiryte/WellDefinedFramework.svg";

//HoverCardWithButton
import HoverCardWithButton from "../components/HoverCardWithButton";
import hoverCardImg11 from "../assets/Images/Hire Remote team/Scale your team for new and existing projects.svg";
import hoverCardImg12 from "../assets/Images/Hire Remote team/Transition an existing team.svg";
import hoverCardImg13 from "../assets/Images/Hire Remote team/Build new remote capabilities, from one person to whole team.svg";

// Mobile movie slider
import MobileMovieSlider from "../components/MobileMovieSlider";
import awardsForMobile from "../assets/Images/About Us/awards____2x.png";

import ourOffer from "../assets/Images/scrollImages/Group_6110_2x.png";

import LocationCards from "../templates/OurLocation";
import mapImage from "../assets/Images/Homepage/Locations/location.png";
import Banglore from "../assets/Images/Homepage/Locations/Banglore.png";
import Chennai from "../assets/Images/Homepage/Locations/Chennai.png";
import Coimbatore from "../assets/Images/Homepage/Locations/Coimbatore.png";
import Hyderabad from "../assets/Images/Homepage/Locations/Hyderabad.png";
import Mumbai from "../assets/Images/Homepage/Locations/Mumbai.png";
import UK from "../assets/Images/Homepage/Locations/UK.png";

import ImageSlider from "../templates/ImageSlider";
import SliderImg1 from "../assets/Images/Homepage/OurPartnership/GoogleCloud.png";
import SliderImg2 from "../assets/Images/Homepage/OurPartnership/MicroSoft_Azure.png";
import SliderImg3 from "../assets/Images/Homepage/OurPartnership/aws-partner-network-logo.png";
import SliderImg4 from "../assets/Images/Homepage/OurPartnership/GMCC-Member-Logo-white.png";
import SliderImg5 from "../assets/Images/Homepage/OurPartnership/HM-Government-G-Cloud-Supplierpng.png";
import SliderImg6 from "../assets/Images/Homepage/OurPartnership/Pro-manchester_Logo.png";

const TeamAugmentation = () => {
  const [articleRawData, setArticleRawData] = useState([]);
  const [articleLimit, setArticleLimit] = useState(3);
  const [articlePage, setArticlePage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      "/admin/api/all-services?limit=1&type=White Paper&service_type=Digital Transformation"
    ).then((response) => {
      setMegaViewData(response);
    });
  }, []);

  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${articleLimit}&page=${articlePage}&type=Article&service_type=Digital Transformation&component=mini_view`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleLimit, articlePage]);

  /**
   * Image Banner
   */
  const ImageBannerData = {
    bnrImg: BannerBgImage,
    bnrImgTitle: <>Team Augmentation</>,
    bnrImgSubTitle: "How can we help you today?",
    servicesColumn1: {
      data: [
        {
          service: "Team Augmentation",
          link: "/team-augmentation",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Digiryte Pods",
          link: "/digiryte-pods",
        },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Product Development",
          link: "/product-development",
        },
      ],
    },
  };

  /**
   * Infoview
   */
  const InfoviewData = {
    InfoviewTitle: (
      <div className="mbl-br-hide ttc">
        Upgrade Your Team, <br /> <span className="red-line3letter">The</span>{" "}
        Easy Way
      </div>
    ),
    InfoviewContent: (
      <>
        Respected IT leaders and Entrepreneurs have chosen Digiryte’s Augmented
        team model for a number of compelling reasons. Popular reasons for
        building an offshore capabilities are ease of in-house recruitment and
        knowledge retention. The eagle-eyed leaders in the industries are
        recognising India as a hot and happening location for team augmentation
        owing to the fact that India has established itself as a global
        innovation hub. <br /> <br />
        There has never been a better time to build an agile team with Digiryte.
        With the new way of working to suit the new standard due to
        technological advancements and a shift in mindset, there’s never a
        better time to make the switch and make the most of its tremendous
        advantages.
      </>
    ),
  };

  /**
   * Infoview1
   */
  const InfoviewData1 = {
    InfoviewTitle: (
      <>
        {" "}
        <span className="red-line3letter">Mor</span>e From This Service{" "}
      </>
    ),
    InfoviewContent: (
      <>
        The augmented team approach provides a scalable flexible solution that
        can efficiently fill gaps of specialised skills. Explore the categories
        of our comprehensive technical expertise.
      </>
    ),
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  const PlainCardData = {
    cardsData: [
      {
        HoverCardWithBtntitle: "Microservices based",
        HoverCardWithBtnsubtitle: "(Improves Agility)",
        HoverCardWithBtnIcon: hoverCardImg1,
        HoverCardWithBtnIconAlt: "Microservices based",
        HoverCardIconClass: "plain-card-button-main-img",
      },
      {
        HoverCardWithBtntitle: "API-first",
        HoverCardWithBtnIcon: hoverCardImg2,
        HoverCardWithBtnIconAlt: "API-first",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "(Secure Software Development Life-cyle)",
      },
      {
        HoverCardWithBtntitle: "Cloud-native SaaS",
        HoverCardWithBtnIcon: hoverCardImg3,
        HoverCardWithBtnIconAlt: "Cloud-native SaaS",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "(Cost cutting without Compromising Quality)",
      },
      {
        HoverCardWithBtntitle: "Headless",
        HoverCardWithBtnIcon: hoverCardImg4,
        HoverCardWithBtnIconAlt: "Headless",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "(Months to Minutes)",
      },
      {
        HoverCardWithBtntitle: "ISO 27001 Compliant",
        HoverCardWithBtnIcon: hoverCardImg5,
        HoverCardWithBtnIconAlt: "ISO 27001 Compliant",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "(Secure Software Development Lifecyle)",
      },
    ],
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };

  /**
   * Hover card button
   */

  const hoverCardButtonData = [
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Scale Your Team for New and Existing Projects",
      HoverCardWithBtnIcon: hoverCardImg11,
      HoverCardWithBtnIconAlt: "Delivery",
      HoverCardIconClass: "mb-10",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Transition an Existing Team",
      HoverCardWithBtnIcon: hoverCardImg12,
      HoverCardWithBtnIconAlt: "Processes",
      HoverCardIconClass: "mb-10",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle:
        "Build new tech capabilities, from one person to whole team",
      HoverCardWithBtnIcon: hoverCardImg13,
      HoverCardWithBtnIconAlt: "Output",
      HoverCardIconClass: "mb-10",
    },
  ];
  /**
   * Mini Image Banner 1
   */

  const MiniImageBanner1Data = {
    title: (
      <>
        Who we work <br /> with
      </>
    ),
    titleClass: "mini-image-banner-title-area3 title-header2",
    image: MiniImageBannerBgImg1,
    altTxt: "Who we work with",
    btnClassName: "image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/who-we-work-with",
    underline: true,
    tooltip: "yes",
    tip: "TAKE A LOOK",
    underlineClass: "black-line",
  };
  /**
   * Blur Card List Data
   */

  const BlurCardListData = {
    cardsData: [
      {
        image: MiniCardBgImage1,
        title: "Mobile Application Development",
        subTitle:
          "Take business to your customers by building advanced native and hybrid mobile apps for both Android and iOS Platforms.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mobile-application-development",
      },
      {
        image: MiniCardBgImage2,
        title: "Web Application Development",
        subTitle:
          "Accelerate different functions of your business by creating custom web applications using the latest in digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/web-application-development",
      },
      {
        image: MiniCardBgImage3,
        title: "Full Product Development",
        subTitle:
          "The process of full product development encompasses all steps needed to take your product from concept to market place.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/full-product-development",
      },
      {
        image: MiniCardBgImage4,
        title: "MVP Development",
        subTitle:
          "Build a basic model to demonstrate the core functionality of your product, test it with early adopters and fulfil the primary goal of your business.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mvp-development",
      },
      {
        image: MiniCardBgImage5,
        title: "Product Designing and Prototyping",
        subTitle:
          "Create a prototype of your product to visualise your idea and generate feedback without incurring the building costs of a fully functioning system.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-designing-and-prototyping",
      },
      {
        image: MiniCardBgImage6,
        title: "Product Scaling",
        subTitle:
          "Found your product-market fit? Great! Let us help you with a profitable scaling process that provides both sustainability and stability for your product.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-scaling",
      },
      {
        image: MiniCardBgImage7,
        title: "Code & UX Audit",
        subTitle:
          "With a regular audit, we can help you fix your product, improve its performance, gain new users, and boost your brand reputation in the marketplace.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/code-ux-audit",
      },
      {
        image: MiniCardBgImage8,
        title: "Digital Transformation",
        subTitle:
          "Update your traditional business processes and customer experience to meet the ever-changing market requirements with the help of next-generation digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/digital-transformation",
      },
      {
        image: MiniCardBgImage9,
        title: "Machine Learning",
        subTitle:
          "Through machine learning, we can help you make informed decisions to improve business scalability and operational efficiency.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/machine-learning",
      },
    ],
    // lastCardData: {
    //   title: "Let's Talk",
    //   subTitle: "We'd love to help with your product.",
    //   variant: "contained",
    //   label: "GET IN TOUCH",
    //   customClass: "miniCrdBgImg-main-border",
    //   route: "/contact-us",
    // },
  };

  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };

  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };

  //Movie Slider
  const movieSlider = {
    imageUrl: scrollImage,
  };

  /**
   * Six Hover Card
   */

  const SixHoverCardData = {
    customClass: "mbl-card-height",
    page: "digiryte-pods",
    HoverCardWithBtnvariant: "contained",
    HoverCardWithBtncolor: "primary",
    HoverCardWithBtnlabel: "LET'S TALK",
    HoverCardWithBtntitle: "Let’s Build Your MVP",
    HoverCardWithBtntitle1: "Readily Available Setup Across Major Cities",
    HoverCardWithBtntitle2:
      "25+ years experienced management presence to oversee",
    HoverCardWithBtntitle3:
      "Well defined framework to make your business auto scale",
    HoverCardWithBtntitle4: "SOPs to kick start the process from Week 1",
    HoverCardWithBtntitle5: "Skilled Resource Availability On Demand",
    HoverCardWithBtnIcon1: HoverCardWithBtnIcon1,
    HoverCardWithBtnIcon2: HoverCardWithBtnIcon2,
    HoverCardWithBtnIcon3: HoverCardWithBtnIcon3,
    HoverCardWithBtnIcon4: HoverCardWithBtnIcon4,
    HoverCardWithBtnIcon5: HoverCardWithBtnIcon5,
    HoverCardWithBtnContentClass: "btncontent mt-10 mbl-card-height",
    HoverCardWithBtnIconAlt1: "E-Commerce Website Development ",
    HoverCardWithBtnIconAlt2: "SAAS Product Development ",
    HoverCardWithBtnIconAlt3: "Platform Based Product Development ",
    HoverCardWithBtnIconAlt4: "CRM Development ",
    HoverCardWithBtnIconAlt5: "LMS Development ",
    whiteCard: "yes",
    lastCardData: {
      height: "490",
      service: "machineLearning",
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      tooltip: "yes",
      tip: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };

  /**
   * Icon Card List Data
   */

  const IconCardListData = [
    {
      icon: IconCardListIcon4,
      altTxt:
        "Augmented employees with English fluency, averaging over 160 hours of work a month",
      heading: (
        <>
          Employees with English
          <br /> Fluency, Averaging
          <br />
          Over 160 Hours of Work a
          <br /> Month
        </>
      ),
    },
    {
      icon: IconCardListIcon2,
      altTxt: "Reduction of employee turnover rate",
      heading: (
        <>
          Ability To Connect With <br /> Your Team In Real <br /> Time, During
          Normal <br /> Business Hours
        </>
      ),
    },

    // {
    //   icon: IconCardListIcon9,
    //   altTxt:
    //     "Ability to connect with your team in real time, during normal business hours",
    //   heading: (
    //     <>
    //       Ability To Connect With
    //       <br /> Your Team In Real
    //       <br /> Time, During Your Normal <br />
    //       Business Hours
    //     </>
    //   ),
    // },
    {
      icon: IconCardListIcon1,
      altTxt: "Guaranteed substantial savings on payroll",
      heading: (
        <>
          Guaranteed <br />
          Substantial savings <br /> on Payroll
        </>
      ),
    },
    {
      icon: IconCardListIcon7,
      altTxt: "Reduction of employee turnover rate",
      heading: (
        <>
          Reduction of employee <br /> turnover rate{" "}
        </>
      ),
    },
    {
      icon: IconCardListIcon3,
      altTxt: "Reduced time spent on HR functions as we handle all",
      heading: (
        <>
          Reduced Time Spent On <br /> HR Functions as we <br />
          Handle All
        </>
      ),
    },
    {
      icon: IconCardListIcon5,
      altTxt: "Minimise overheads by upto 60%",
      heading: (
        <>
          Minimise Overheads by
          <br /> Upto 60%
        </>
      ),
    },
    // {
    //   icon: IconCardListIcon6,
    //   altTxt: "Increased productivity and profitability",
    //   heading: (
    //     <>
    //       Increased <br />
    //       Productivity and <br />
    //       Profitability
    //     </>
    //   ),
    // },
    // {
    //   icon: IconCardListIcon7,
    //   altTxt:
    //     "Complete transparency - No hidden fees, just one monthly payment",
    //   heading: (
    //     <>
    //       Complete Transparency -<br /> No Hidden Fees, Just
    //       <br /> One Monthly Payment
    //     </>
    //   ),
    // },
    // {
    //   icon: IconCardListIcon8,
    //   altTxt: "Highly skilled, motivated and productive employees",
    //   heading: (
    //     <>
    //       Highly Skilled, Motivated
    //       <br /> and Productive
    //       <br /> Employees
    //     </>
    //   ),
    // },
  ];
  // Mobile movie slider
  const mobMovieSlider = {
    imageUrl: awardsForMobile,
  };
  const mobMovieSlider1 = {
    imageUrl: ourOffer,
  };

  /**
   * Medium View
   */

  const MediumviewData = {
    title: (
      <div className="ttc">
        Engage with us if you <br />
        <span className="red-line3letter red-3letter-mbl">Wan</span>t to
      </div>
    ),
    underline: true,
    individualBoxData: {
      IndividualBoxTitle: "Explore synergies",
      IndividualBoxBtnLabel: "GET IN TOUCH",
      IndividualBoxBtnVariant: "contained",
      IndividualBoxBtnColor: "primary",
      route: "/contact-us",
    },
    data: [
      {
        MediumviewImage: MediumviewImage2,
        MediumviewAlt: "On-Shore Model",
        MediumviewHeading:
          "Extend Your Team Internationally to Meet Your Delivery Goals",
        MediumviewContent: (
          <>
            The budget and resources required to set up an entity do not have to
            deter your business from hiring a global agile team. Digiryte takes
            on the burden of legal, tax and financial setup, allowing you to
            quickly secure a premium offshore agile team, and do it fast without
            the delay of setting up new companies.
          </>
        ),
        MediumviewButtonColor: "primary",
        MediumviewButtonLabel: "Find Out More",
        route: "/contact-us",
      },
      {
        MediumviewImage: MediumviewImage3,
        MediumviewAlt: "Off-Shore Model",
        MediumviewHeading:
          "Stay Compliant With Local Laws and Protect Interests of Your Business",
        MediumviewContent: (
          <>
            Laws and regulations change fast. It’s a full-time job just keeping
            up with them in your main country of business. As you add employees
            living in other countries to the list, the resources required to
            stay up-to-date and compliant will multiply. Let Digiryte handle
            this on an ongoing basis so you can focus on growing your business
            by meeting your delivery goals instead of staying compliant.
          </>
        ),
        MediumviewButtonColor: "primary",
        MediumviewButtonLabel: "Find Out More",
        route: "/contact-us",
      },
      {
        MediumviewImage: MediumviewImage1,
        MediumviewAlt: "Dual-Shore Model",
        MediumviewHeading:
          "Retain Resources Long-term with Knowledge Retention and Domain Expertise",
        MediumviewContent: (
          <>
            Part of getting the most out of your internal employees and extended
            team while ensuring they love their new role to help the
            organisation to meet their delivery goals without any worry about
            attrition rate. Digiryte frees up your internal team to focus on
            building the relationship with your newly augmented team members and
            assures long-term retention.
          </>
        ),
        MediumviewButtonColor: "primary",
        MediumviewButtonLabel: "Find Out More",
        route: "/contact-us",
      },
    ],
  };
  const mobMovieSlider2 = {
    imageUrl: BusinessBenifited,
  };
  const LocationCardData = {
    mapImage: mapImage,
    locationImages: [
      {
        image: UK,
        imgTitle: (
          <>
            Manchester <br /> (HQ)
          </>
        ),
        route: "/locations/manchestor",
      },
      {
        image: Banglore,
        imgTitle: "Bangalore",
        route: "/locations/bangalore",
      },
      {
        image: Hyderabad,
        imgTitle: "Hyderabad",
        route: "/locations/hyderabad",
      },
      {
        image: Mumbai,
        imgTitle: "Mumbai",
        route: "/locations/mumbai",
      },
      {
        image: Chennai,
        imgTitle: "Chennai",
        route: "/locations/chennai",
      },
      {
        image: Coimbatore,
        imgTitle: "Coimbatore",
        route: "/locations/coimbatore",
      },
    ],
  };

  const ImageSliderData = {
    ImageClass: "slider-img-short-logos team-aug-slider-style",
    responsive: {
      0: { items: 2 },
      360: { items: 3 },
      600: { items: 2 },
      700: { items: 3 },
      1024: { items: 4 },
      1360: { items: 5 },
      1600: { items: 6 },
    },
    galleryItems: [
      {
        src: `${ARUP}`,
        alt: "ARUP",
      },
      {
        src: `${NetworkPlus}`,
        alt: "NetworkPlus",
      },
      // {
      //   src: `${Hedgehog}`,
      //   alt: "Hedgehog"
      // },
      {
        src: `${MROPRO}`,
        alt: "MROPRO",
      },
      {
        src: `${CERN}`,
        alt: "CERN",
      },
      // {
      //   src: `${SliderImg5}`,
      //   alt: "DON"
      // },
      // {
      //   src: `${SliderImg6}`,
      //   alt: "Mic Computers"
      // },
    ],
  };

  const MiniImageBanner2Data = {
    route: "/contact-us",
    id: 2,
    title: (
      <div className="bannerlove">
        <h3 className="mini-image-banner-title-area2 title-header bannerlove">
          We'd love to help with
        </h3>

        <div className="services-link additional-bnr-flex-btn">
          <h3 className="title-header">
            your product.{" "}
            <span className="mini-img-bnr-btn-link">Get in touch</span>
          </h3>

          <div className="animated-underline"></div>
        </div>
      </div>
    ),
    image: MiniImageBannerBgImg,
    altTxt: "Get in touch",
  };
  const LocationCardData1 = {
    mapImage: mapImage1,
    locationImages: [
      {
        image: Manchester,
        imgTitle: (
          <>
            Manchester <br /> (HQ)
          </>
        ),
        route: "/locations/manchestor",
      },
      {
        image: mBangalore,
        imgTitle: "Bangalore",
        route: "/locations/bangalore",
      },
      {
        image: mHyderabad,
        imgTitle: "Hyderabad",
        route: "/locations/hyderabad",
      },
      {
        image: mMumbai,
        imgTitle: "Mumbai",
        route: "/locations/mumbai",
      },
      {
        image: mChennai,
        imgTitle: "Chennai",
        route: "/locations/chennai",
      },
      {
        image: mCoimbatore,
        imgTitle: "Coimbatore",
        route: "/locations/coimbatore",
      },
    ],
  };
  const InfoviewData2 = {
    InfoviewTitle: (
      <div className="mbl-br-hide ttc">
        <span className="red-line3letter">Why</span> Digiryte for your Team
        Extention?
      </div>
    ),
    InfoviewContent: (
      <>
        Digiryte provides a dedicated agile team with the required office
        infrastructure and manpower, including administrative and support staff,
        necessary to complete the development process. This not only helps the
        client business to scale but also helps the client to optimise their
        cost and enables the client to focus more on their business developments
        and other key value adds to their organisation.
      </>
    ),
  };
  return (
    <Layout
      seo={{
        title: "Team Augmentation",
        ogtitle: "Team Augmentation | Digiryte",
        description:
          "We assist in hiring, managing and implementing all the relevant human resource functions for you to start building & scaling a reliable and dynamic remote teams",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/team-augmentation`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-50 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/what-we-do"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          What we do
        </Typography>
      </div>
      <div className="mt-70">
        <Infoview InfoviewData={InfoviewData} />
      </div>
      <div className="mt-70 body-container">
        <h3 className="title-header ttc">
          What’s So Great About <br />
          <span className="red-line3letter">Aug</span>mented Team?
        </h3>
      </div>
      <div className="mt-50"></div>
      <SixPlainCards SixHoverCardData={SixHoverCardData} />
      <div className="body-container mt-70">
        <h5 className="title-header ttc">
          <span className="red-line3letter">We </span> Offer
        </h5>
        <div className="mt-50">
          <div className="hide-in-mobile">
            {" "}
            <IconCardList IconCardListData={IconCardListData} />
          </div>
          <MobileMovieSlider movieSlider={mobMovieSlider1} slideid="slide2" />
        </div>
      </div>
      <div className="mt-70">
        <Infoview InfoviewData={InfoviewData2} />
      </div>
      <SixPlainCards SixHoverCardData={SixHoverCardData} />
      <div className="mt-70 body-container ttc">
        <span className="title-header mbl-br-hide">
          With Digiryte’s Augmented Team <br />{" "}
          <span className="red-line3letter">Sol</span>ution you can
        </span>
      </div>
      <div className="body-container mt-50 hide-in-mobile">
        <Grid container direction="row" spacing={3}>
          {hoverCardButtonData.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <Card className="appsgridshadow">
                <HoverCardWithButton
                  componentName="product-design"
                  pageName="hire-remote"
                  HoverCardWithBtnCustomClass={item.HoverCardWithBtnCustomClass}
                  HoverCardWithBtntitle={item.HoverCardWithBtntitle}
                  HoverCardWithBtnIcon={item.HoverCardWithBtnIcon}
                  HoverCardWithBtnIconAlt={item.HoverCardWithBtnIconAlt}
                  HoverCardIconClass={item.HoverCardIconClass}
                  HoverCardWithBtnsubtitle={item.HoverCardWithBtnsubtitle}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      <Grid className="show-only-in-mobile">
        <MobileSmoothSlider reduceheight="no" movieSlider={movieSlider} />
      </Grid>
      <div className="mt-70 body-container">
        <h3 className="title-header ">
          {" "}
          <span className="red-line3letter">Bus</span>iness Benefited
        </h3>

        <div className="mt-50">
          <MobileMovieSlider
            componentName="digiryte-pods-hght"
            movieSlider={mobMovieSlider2}
            slideid="slide3"
          />

          <ImageSlider ImageSliderData={ImageSliderData} />
        </div>
      </div>
      <div className="mt-50">
        <MiniImageBanner1
          web="no"
          id="two"
          MiniImageBanner1Data={MiniImageBanner2Data}
        />
      </div>
      <div className="mt-50"></div>
      <MediumviewWithHover MediumviewData={MediumviewData} />
      {/* <div className="mt-50"></div>
      {megaviewData.data && megaviewData.data.length > 0 && (
        <div className="mt-70 body-container">
          <Megaview MegaviewData={megaviewData.data} />
        </div>
      )} */}
      <div className="mt-70">
        <Infoview InfoviewData={InfoviewData1} />

        {/* <span className="title-header">
          <span className="red-line3letter">Mor</span>e From This Service
        </span> */}
      </div>
      <div className="mt-70">
        <BlurCardList
          loadMore
          componentId="1"
          BlurCardListData={BlurCardListData}
        />
      </div>
      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container ttc">
            <span className="title-header">
              <span className="red-line3letter">Rec</span>ommended Reading
            </span>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              miniviewData2={miniviewDataArticle}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(
                articlePage === 1 ? articlePage + 2 : articlePage + 1
              );
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="READ MORE"
              tip="READ MORE"
            />
          </div>
        </div>
      )}
      {/* <div className="body-container mt-70 ttc">
        <span className="title-header">
          <span className="red-line3letter">Oth</span>er Services
        </span>
      </div>
      <div className="mt-70">
        <BlurCardList componentId="1" BlurCardListData={BlurCardListData} />
      </div> */}
      <div className="body-container mt-70 ttc">
        <h2 className="title-header">
          <span className="red-line3letter red-3letter-mbl">Our </span>
          Locations
        </h2>
      </div>
      <div className="hide-in-mobile">
        <LocationCards
          mapImage={mapImage}
          label="View Location"
          LocationCardData={LocationCardData}
        />
      </div>
      <div className="hide-in-web">
        <LocationCards
          mapImage={mapImage1}
          label="View Location"
          LocationCardData={LocationCardData1}
        />
      </div>{" "}
      {/* <div className="mt-70"></div> */}
      <TwoColumnContactInfo
        TwoColumnContactInfoData={TwoColumnContactInfoData}
      />
    </Layout>
  );
};

export default TeamAugmentation;
